<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard title="Comunas" subtitle="Listado de comunas asiganadas a la tienda"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                no-data-text="No hay comunas asignadas"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">
                      {{ props.item.name }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.value }}
                    </td>
                    <td>
                      {{ props.item.hora_corte }}
                    </td>
                    <td @click="changeStatus('estado', props.item.id, !props.item.active)">
                      <app-status :value="props.item.active"></app-status>
                    </td>
                    <td @click="changeStatus('quarantine', props.item.id, !props.item.quarantine)">
                      <app-status :value="props.item.quarantine"></app-status>
                    </td>
                    <td>
                      <v-icon
                        v-if="user.role.id === 1 || user.role.id === 2"
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                      >
                        far fa-edit
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <app-title-dialog :title="'Modificar comuna'" :close-action="() => (dialog = false)" close> </app-title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field v-model="item.name" label="Nombre" hide-details></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field v-model="item.value" label="Valor" hide-details></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field v-model="item.hora_corte" label="Hora de corte" hide-details></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="item.active" label="Activo" hide-details class="mt-1"> </v-switch>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="item.quarantine" label="Planes" hide-details class="mt-1"> </v-switch>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import appTitleDialog from '../useful/titleDialog.vue'
import appStatus from '../useful/status.vue'
import titleCard from '../useful/titleCard.vue'
import { GET_ZONES } from '../../config'

export default {
  name: 'StoreStates',
  components: { appTitleDialog, appStatus, titleCard },
  data: () => ({
    loading: false,
    dialog: false,
    items: [],
    item: {
      name: null,
      active: false,
      hora_corte: null,
      quarantine: null,
      shop_id: null,
      value: null
    },
    headers: [
      { text: 'Nombre', align: 'left', sortable: false },
      { text: 'Valor', align: 'left', sortable: false },
      { text: 'Hora de corte', align: 'center', sortable: false },
      { text: 'Activo', align: 'center', sortable: false },
      { text: 'Planes', align: 'center', sortable: false },
      { text: '', align: 'right', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {
        const res = await this.$http({
          url: GET_ZONES,
          method: 'GET'
        })
        this.items = res.data
      } catch (e) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async changeStatus(type, id, status) {
      try {
        await this.$http.get(`${GET_ZONES}/${id}/status`, {
          params: {
            type,
            status
          }
        })
        this.getItems()
      } catch (e) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async save() {
      try {
        await this.$http.put(
          `${GET_ZONES}/${this.item.id}`,
          { item: this.item },
          {
            emulateJSON: true
          }
        )
        this.getItems()
        this.dialog = false
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    editItem(item) {
      if (item) {
        this.item = item
      }
      this.dialog = true
    }
  }
}
</script>
